import { useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Image, Row, Tabs, Tab } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetUsers } from '../../Api/api';
import { ASSIGNED_PROJECT_STATUS, CATEGORY_ID, CATEGORY_TYPE_ID, STATUS_OF_PROJECT } from '../Common/Constant/Constant';
import DiscriptionTab from './Discription';
import ImageConfiguration from './ImageConfiguration';
import DocumentConfiguration from './DocumentConfiguration';
import InvestmentDetails from './InvestmentDetails';
import InvestmentDetailsFund from './InvestmentDetailsFund';
import ArtDetails from "./ArtDetails";
import WatchDetails from "./WatchDetails";
import GoldDetails from "./GoldDetails";
import InvestmentDetailsWatch from "./InvestmentDetailsWatch";
import InvestmentDetailsParcel from "./InvestmentDetailsParcel";
import InvestmentDetailsSale from "./InvestmentDetailsSale";
import SignedDocumentTemplate from './SignedDocumentTemplate';
import DefaultTemplate from './DefaultTemplate';
import RealEstateDetails from './RealEstateDetails';
import moment from "moment";
import VideoConfiguration from './VideoConfiguration';
import AssignSuperAdmin from './AssignSuperDic';
import AssignSuperDic from './AssignSuperDic';
import { BsNutFill } from 'react-icons/bs';
import DealTerms from './DealTerms';


function ProjectDetail() {

  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([])
  const location = useLocation();
  const { state } = location;
  const { project_id } = useParams();

  /**
   * initially calling getAssetsList api
   */
  useEffect(() => {
    /**
     * Fetch project data using getAssetsList api using particular asset Id
     */
    async function getCategoryData() {
      try {
        const res = await GetUsers(`/admin/getAssetDetails?asset_id=${project_id}`);
        if (res.data) {
          setProjectData(res?.data?.data ? res?.data?.data : [])
        }
      } catch (error) {
        console.log(error);
      }
    }
    getCategoryData();
  }, [project_id]);


  /**
    * Scroll to the top of the page on component mount (refresh).
    */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="head">
        <Row className="">
          <Col>
            <div className="mainHeader">
              {
                <div className="BtnBox" >
                  <h4>Project Detail</h4>
                </div>
              }
            </div>
          </Col>
        </Row>
      </div>
      <div className="breadcrumb headingText">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/dashboard')} >Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate('/projects')} >Projects List</Breadcrumb.Item>
          <Breadcrumb.Item active >Project Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card className='card mb-4 custom-card'>
        <Row className="align-items-center">
          <Col xs={3} lg={2} md={3}>
            {projectData?.asset_title && (
              <div className='userNameHeading mb-3'>
                <Image className='userImage' src={`${process.env.REACT_APP_IMAGE_URL}` + projectData?.asset_gallery[0]?.images.path} rounded />
              </div>
            )}
          </Col>
          <Col xs={12} lg={10} md={9} className='content'>
            <div className='userNameBlock'>
              <div className='profileDetail'>
                <div className='userDetail' >
                  <div style={{ marginRight: '8px' }}><strong>Project Name</strong>: <span>{projectData?.asset_title} </span> </div>
                  <div style={{ marginRight: '8px' }}><strong>Category </strong> : <span>{projectData?.assets_category?.title === "Asset" ? "Luxury Cars" : projectData?.assets_category?.title}</span></div>
                  <div style={{ marginRight: '8px' }}><strong>Project Type</strong>: <span>{projectData?.investment_type?.display_name}</span> </div>
                  <div style={{ marginRight: '8px' }}><strong>Status</strong>: <span>{projectData?.project_status === STATUS_OF_PROJECT.DRAFT
                    ? 'Draft'
                    : projectData?.project_status === STATUS_OF_PROJECT.INREVIEW
                      ? 'In Review'
                      : projectData?.project_status === STATUS_OF_PROJECT.SUBMITTED
                        ? 'Submitted'
                        : projectData?.project_status === STATUS_OF_PROJECT.REVIEWED
                          ? 'Reviewed'
                          : projectData?.project_status === STATUS_OF_PROJECT.PROJECT_LIVE
                            ? 'Live'
                            : projectData?.project_status === STATUS_OF_PROJECT.REJECTED
                              ? 'Rejected'
                              : null}</span></div>
                  <div style={{ marginRight: '8px' }}><strong>Created At</strong>: <span>{moment(projectData?.createdAt).format("DD/MM/YYYY")}</span>  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <div className="custom-tabs-container project-detail-tab-sec">
        <Tabs defaultActiveKey="discription" id="project-tabs" className="custom-tabs">
          <Tab eventKey="discription" title="Description" className="custom-tab" >
            <div className="tab-content">
              {projectData && <DiscriptionTab
                discription={projectData?.asset_description}
                parcelData={projectData?.parcelData}
              />}
            </div>
          </Tab>
          <Tab eventKey="images" title="Image" className="custom-tab">
            <div className="tab-content">
              <ImageConfiguration images={projectData?.asset_gallery} />
            </div>
          </Tab>
          <Tab eventKey="documents" title="Document" className="custom-tab">
            <div className="tab-content">
              <DocumentConfiguration documentsData={projectData?.asset_document?.length ? projectData?.asset_document : []
              } descriptionData={projectData?.description_document?.length ? projectData?.description_document : []} />
            </div>
          </Tab>
          <Tab eventKey="video" title="Video" className="custom-tab">
            <div className="tab-content">
              <VideoConfiguration documentsData={projectData?.asset_document?.length ? projectData?.asset_document : []
              } descriptionData={projectData?.description_document?.length ? projectData?.description_document : []} />
            </div>
          </Tab>
          {state?.categoryData?.category_id === CATEGORY_ID.REAL_ESTATE &&
            <Tab eventKey="realestate" title="Asset Details" className="custom-tab">
              <div className="tab-content">
                <RealEstateDetails realEstateData={projectData} />
              </div>
            </Tab>}
          {state?.categoryData?.category_id === CATEGORY_ID.REAL_ESTATE &&
            <Tab eventKey="investment" title="Investment Detail" className="custom-tab">
              <div className="tab-content">
                <InvestmentDetails capitalType={projectData?.capital_type} capitalInfo={projectData?.capital_info} />
              </div>
            </Tab>}
          {state?.categoryData?.category_id === CATEGORY_ID.FUND &&
            <Tab eventKey="investment" title="Investment Detail" className="custom-tab">
              <div className="tab-content">
                <InvestmentDetailsFund capitalType={projectData?.capital_type} capitalInfo={projectData?.capital_info} />
              </div>
            </Tab>}
          {state?.categoryData?.category_id === CATEGORY_ID.FUND &&
            <Tab eventKey="funddetail" title="Fund Details" className="custom-tab">
              <div className="tab-content">
                <RealEstateDetails realEstateData={projectData} from={"fund"} />
              </div>
            </Tab>}
          {state?.categoryData?.category_id === CATEGORY_ID.ART &&
            <Tab eventKey="artdetails" title="Art Details" className="custom-tab">
              <div className="tab-content">
                <ArtDetails artData={projectData.artsData} artSpecificData={projectData} />
              </div>
            </Tab>}
          {state?.categoryData?.category_id === CATEGORY_ID.ART && state?.categoryData?.investment_type.display_name ===
            "Parcel" ?
            <Tab eventKey="investmentdetailparcel" title="Investment Detail " className="custom-tab">
              <div className="tab-content">
                <InvestmentDetailsParcel ParcelDetails={projectData} />
              </div>
            </Tab> : null}
          {state?.categoryData?.category_id === CATEGORY_ID.ART && state?.categoryData?.investment_type.display_name ===
            "Sale" ?
            <Tab eventKey="investmentdetailsale" title="Investment Detail" className="custom-tab">
              <div className="tab-content">
                <InvestmentDetailsSale SaleDetails={projectData} />
              </div>
            </Tab> : null}
          {state?.categoryData?.category_id === CATEGORY_ID.WATCH || state?.categoryData?.category_id === CATEGORY_ID.GOLD ?
            <Tab eventKey="investmentdetails" title="Investment Details" className="custom-tab">
              <div className="tab-content">
                <InvestmentDetailsWatch WatchInvestment={projectData} />
              </div>
            </Tab> : null}
          {state?.categoryData?.category_id === CATEGORY_ID.GOLD &&
            <Tab eventKey="golddetails" title="Gold Details" className="custom-tab">
              <div className="tab-content">
                <GoldDetails GoldData={projectData} />
              </div>
            </Tab>}
          {state?.categoryData?.category_id === CATEGORY_ID.WATCH &&
            <Tab eventKey="watchdetails" title="Watch Details" className="custom-tab">
              <div className="tab-content">
                <WatchDetails WatchData={projectData} />
              </div>
            </Tab>
          }
          {(projectData?.asset_signing_document && projectData?.asset_signing_document?.length) ?
            <Tab eventKey="signeddocuments" title="Docusign Template" className="custom-tab">
              <div className="tab-content">
                <SignedDocumentTemplate documentsData={projectData?.asset_signing_document} templateName={projectData?.template_name} assetId={project_id} />
              </div>
            </Tab>
            : null
          }
          {(projectData?.template_data && projectData?.template_data?.length) ?
            <Tab eventKey="defaulttemplates" title="Default Templates" className="custom-tab">
              <div className="tab-content">
                <DefaultTemplate templateData={projectData?.template_data[0]?.template_ids} />
              </div>
            </Tab>
            : null
          }
          {(projectData?.assets_category?.id === CATEGORY_TYPE_ID.GOLD || projectData?.assets_category?.id === CATEGORY_TYPE_ID.ASSET || projectData?.assets_category?.id === CATEGORY_TYPE_ID.WATCH ||projectData?.assets_category?.id === CATEGORY_TYPE_ID.FUNDS || (projectData?.assets_category?.id === CATEGORY_TYPE_ID.ART && projectData?.investment_type_id == 1)) ?
            <Tab eventKey="dealterm" title="Deal Terms" className="custom-tab">
              <div className="tab-content">
                <DealTerms DealTermData={projectData} />
              </div>
            </Tab>
            : null
          }
          {projectData?.project_status === STATUS_OF_PROJECT.REVIEWED ?
            <Tab eventKey="assignsuperdic" title="Assign Super Dic" className="custom-tab">
              <div className="tab-content">
                <AssignSuperDic ProjectData={projectData} />
              </div>
            </Tab> : null
          }

        </Tabs>
      </div>
    </>
  );
}
export default ProjectDetail;
