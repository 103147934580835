import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';

const InvestDetailsSale = ({ SaleDetails}) => {

  return (
    <Container fluid className='container-style'>
      <Row className="">
        <Col md={12}>
          {/* Watch Details Section */}
          <Card className='class-style'>
            <Card.Body>
              <h2 className='title-style'>{SaleDetails && SaleDetails?.asset_title}</h2>
              <p className='p-style'>
                <strong>Sale  Price:</strong> {SaleDetails && SaleDetails?.sale_price}
              </p>
              <p className='p-style'>
                <strong>Sale Deposite Amount:</strong> {SaleDetails && SaleDetails?.sale_deposit_amount}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InvestDetailsSale;
