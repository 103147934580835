import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
// import { fetchUserData } from "./thunks";

const fetchUserData = createAsyncThunk(
    "user/fetchUserData",
    async (userId, { extra }) => {
      //const { apiClient } = extra;
    //   const response = await axios.get(`/users/${userId}`);
      return "response.data";
    }
  );

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        login: false,
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
            state.login = true;
            localStorage.setItem('adminuserdata', JSON.stringify(action.payload));
            localStorage.setItem('admintoken', action.payload?.token);
            localStorage.setItem('adminlogin', true);
        },
        logout: (state) => {
            state.user = null;
            state.login = false;
            localStorage.removeItem('adminuserdata');
            localStorage.removeItem('admintoken');
            localStorage.removeItem('adminlogin');
            localStorage.removeItem('adminrefreshtoken');
        },
        update: (state, action) => {
            state.user = { ...state.user, ...action.payload };
            localStorage.setItem('adminuserdata', JSON.stringify(state.user));
        }
    },
    
    extraReducers: (builder) => {
        builder
          .addCase(fetchUserData.pending, (state) => {
            state.status = "loading";
          })
          .addCase(fetchUserData.fulfilled, (state, action) => {

            state.status = "succeeded";
            state.data = action.payload;
          })
          .addCase(fetchUserData.rejected, (state, action) => {

            state.status = "failed";
            state.error = action.error.message;
          });
      },

});

export const { login, logout, update } = authSlice.actions;
export default authSlice.reducer;