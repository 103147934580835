import { readContract, prepareWriteContract, writeContract, waitForTransaction } from "@wagmi/core";


/**
 * 
 * @param {*} data 
 * @returns 
 */
async function AssigndSuperDic(address, blockchain_id , abicontractdata) {
    try {
     
        const dicData = await readContract({
            address: abicontractdata[0]?.address,
            abi: abicontractdata[0]?.abi,
            account: address,
            functionName: "viewAllReviews",
            args: [
                blockchain_id  // dic member wallet address - string
            ]
        });
        return dicData
    } catch (error) {
        console.error('Error in fetching Dic Member Details:', error);
        throw error; // propagate the error
    }
}

export { AssigndSuperDic };
