import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ImageConfiguration = ({ images }) => {
  return (
<Container fluid style={{ marginTop: "20px", maxHeight: "400px", overflowY: "auto" }} className='image-container'>
      <Row>
        {images?.map((image, index) => (
          <Col key={index} lg={3} md={6} sm={6} xs={12} className="mb-4">
            <div className='productBox'>
           
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}${image.images.path}`}
              alt={`Image ${index + 1}`}
              className="img-fluid rounded"
            />
             <div style={{paddingBottom:"10px" , fontWeight:"600"}}>{`Image ${index + 1}`}</div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ImageConfiguration;