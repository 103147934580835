import { lazy, useState, useEffect, useCallback, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Session from "./Session";
import Loader from "./Loader";
import ProjectDetail from "../components/ProjectManagement/ProjectDetail";
import { useAuth } from "../Auth/Login/AuthContext";

/**
 * lazy loader components
 */
const Login = lazy(() => import("../Auth/Login/login"));
const RangeConfig = lazy(() => import("../components/Master/RangeConfigMaster/RangeConfig"));
const RepaymentRangeConfigMaster = lazy(() => import("../components/Master/RangeConfigMaster/RepaymentConfiguration/RepaymentRangeConfigMaster"))
const DicConfig = lazy(() => import("../components/Master/DicConfigMaster/dicConfig"));
const DicConfigList = lazy(() => import("../components/Master/DicConfigMaster/DicConfigList"));
const TransectionList = lazy(() => import("../components/TransectionManagement/TransectionList"));
const TransectionDetails = lazy(() => import("../components/TransectionManagement/TransectionDetails"));
const Sidebars = lazy(() => import("../components/Common/Sidebar/Sidebar"));
const Header = lazy(() => import("../components/Common/Header/Header"));
const ProjectListPage = lazy(() =>
  import("../components/ProjectManagement/Projects")
);
const Dashboard = lazy(() => import("../components/Dashboard/Dashboard"));
const AppUserPage = lazy(() => import("../components/UserManagement/AppUser"));
const RolesList = lazy(() => import("../components/RolesAndPermission/Roles"));
const AddRolesPermission = lazy(() =>
  import("../components/RolesAndPermission/Permission")
);
const SystemUserPage = lazy(() =>
  import("../components/SystemUserManagement/SystemUser")
);
const AddUser = lazy(() =>
  import("../components/SystemUserManagement/AddUser")
);
const UserDetail = lazy(() =>
  import("../components/UserManagement/UserDetail")
);

const CreateBlog = lazy(() =>
  import("../components/BlogManagement/CreateBlog")
);

const BlogList = lazy(() => import("../components/BlogManagement/BlogList"));
const NotFound = lazy(() => import("../components/Pages/404page"));
const Faq = lazy(() => import("../components/Pages/Faq"));
const MemberRequestPage = lazy(() =>
  import("../components/MemberRequest/MemberRequest")
);
const DicMember = lazy(() => import("../components/DicMember/DicMember"));
const TermsCondition = lazy(() =>
  import("../components/CMSContent/TermsCondition")
);
const TermsConditionFoundation = lazy(() =>
  import("../components/CMSContent/TermsConditionFoundation")
);
const Privacypolicy = lazy(() =>
  import("../components/CMSContent/PrivacyPolicy")
);
const PrivacypolicyFoundation = lazy(() =>
  import("../components/CMSContent/PrivacyPolicyFoundation")
);
const Profession = lazy(() => import("../components/AddProfession/Profession"));
const AssetReviewList = lazy(() =>
  import("../components/Master/AssetReviewMaster/List")
);
const PaymentTerm = lazy(() => import("../components/PaymentTerms/PaymentTermMain"));
const AssetReviewConfiguration = lazy(() =>
  import("../components/Master/AssetReviewMaster/Configuration")
);

/**
 * Routes for the Member Request
 */
const Investor = lazy(() => import("../components/MemberRequest/Investor"))
const Proposer = lazy(() => import("../components/MemberRequest/Proposer"))
const ServiceProvider = lazy(() => import("../components/MemberRequest/ServiceProvider"))
const InvestmentCommitteeMember = lazy(() => import("../components/MemberRequest/InvestmentCommitteeMember"))
const LiquidityProvider = lazy(() => import("../components/MemberRequest/LiquidityProvider"))
const ActivityLogs = lazy(() => import("../components/ActivityLog/ActivityLogs"))
const DicMemberReviewPage = lazy(() => import("../components/DicMember/DicMemberReviewPage"))
const InvestmentCommitieDeailPage = lazy(() => import("../components/MemberRequest/InvestmentCommitieDetails"))
const Broker = lazy(() => import("../components/MemberRequest/Broker"))
const AssetCustodyProvider = lazy(() => import("../components/MemberRequest/AssetCustodyProvider"))
const AssetCustodyProviderDetails = lazy(() => import("../components/MemberRequest/AssstCustodyProviderDetails"))
const LawFirm = lazy(() => import("../components/MemberRequest/LawFirm"))
const DecentralizeFinancialAdvisor = lazy(() => import("../components/MemberRequest/DecentralizedFinancialAdvisor"))
const OthersRole = lazy(() => import("../components/MemberRequest/OthersRole"))
const OtherRoleDetailPage = lazy(() => import("../components/MemberRequest/OtherRoleDetailPage"))
const EmailSubscriptions = lazy(() => import("../components/MemberRequest/EmailSubscriptions"))
const TechDeveloper = lazy(() => import("../components/MemberRequest/TechDeveloper"))
const Serviceproviderconfig = lazy(() => import("../components/ServiceProvider/ServiceProviderConfiguration"))
const ServiceProviderSkill = lazy(() => import("../components/ServiceProvider/ServicePorviderSkill"))
const ServiceProviderSevices = lazy(() => import("../components/ServiceProvider/ServiceProviderServices"))
const Language = lazy(() => import("../components/ServiceProvider/Language"))
const SubCategory = lazy(() => import("../components/ServiceProvider/SubCategory"))
const LandingPageConfiguration = lazy(() => import("../components/LandingPageConfiguration/LandingPageConfiguration"))
const ProjectRepaymentConfiguration = lazy(() => import("../components/ProjectManagement/ProjectRepaymentConfiguration"))
const AuctionConfiguration = lazy(() => import("../components/Master/AuctionConfiguration/AuctionConfiguration"))
const ProjectCloserConfiguration = lazy(() => import("../components/Master/ProjectCloserConfiguration/projectCloserConfiguration"))
const HowItWorksConfiguration = lazy(() => import("../components/Master/HowItWorks/HowItWorksMaster"))
const InvestmentTerms = lazy(() => import("../components/Master/InvestmentTerms/InvestmentTermsMain"))
const FaqConfiguration = lazy(() => import("../components/ServiceProvider/FaqConfiguration/FaqMaster"))
const ServiceProviderConfig = lazy(() => import("../components/ServiceProvider/ServiceFeeConfiguration/ServiceFeeConfig"))
const FundInvestmentConfig = lazy(() => import("../components/FundInvestmentConfigration/FundInvestmentConfig"))
const ServiceDetails = lazy(() => import("../components/ServiceManagement/ServiceDetails"))
const ServiceProviderDetails = lazy(()=>import ("../components/ServiceProvider/ServiceProviderList/ServiceProviderDetails/ServiceProviderDetails"))
const ServiceListPage = lazy(() => import("../components/ServiceManagement/ServiceList"))
const ServiceContactSupport = lazy(() => import("../components/ServiceProvider/ServiceContactSupport"))
const ServiceProviderListPage = lazy (()=> import ("../components/ServiceProvider/ServiceProviderList/ServiceProviderList"))
const ProjectReport = lazy(()=>import("../components/Reports/ProjectReport"))
const InvestmentReport = lazy(()=>import("../components/Reports/InvestmentReport"))
const UserReport = lazy(()=>import("../components/Reports/userReport"))
const InvestmentDetailPage =  lazy(()=>import("../components/Reports/InvestmentDetailPage"))
const AdminFeeModuleService = lazy(()=>import("../components/ServiceProvider/AdminFeesConfiguration/AdminFeeModule"))

function AppRouter() {
  // State for managing sidebar collapse/expand and user login
  const [collapsed, setCollapsed] = useState(false);
  const [isloggedIn, setIsloggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("admintoken"));
  const { rememberMe, toggleRememberMe, isLoggedIn } = useAuth();

  // Retrieve the user's token from local storage
  useEffect(() => {
    const storedToken = localStorage.getItem("admintoken");
    if (storedToken) {
      setToken(storedToken);
      setIsloggedIn(true);
    } else {
      setToken(null);
      setIsloggedIn(false);
    }

    const handleStorageChange = () => {
      const updatedToken = localStorage.getItem("admintoken");
      setToken(updatedToken);
      setIsloggedIn(!!updatedToken);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  /**
   * Function to expand/collapse the sidebar
   */
  const expandSidbar = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  /**
   * Function to handle user login
   */
  const handleLogin = useCallback(() => {
    setIsloggedIn(true);
    setToken(localStorage.getItem("admintoken"));
  }, []);


  return (
    <Router>
      {/* Toast notifications */}
      <Toaster />
      {/* Session management */}
      <Session />
      {/* Check if the user is logged in */}
      {token ? (
        <>
          {/* Header component */}
          <Header
            expandSidbar={expandSidbar}
            setIsloggedIn={setIsloggedIn}
            collapsed={collapsed}
          />
          {/* Main container */}
          <div className="main_container">
            {/* Sidebar component */}
            <Sidebars collapsed={collapsed} setIsloggedIn={setIsloggedIn} />

            <div className="main_content">
              <div className="mainWrapper">
                {/* Lazy-loaded routes */}
                <Suspense fallback={<Loader />}>
                  <Routes>

                    {/* Redirect to Dashboard when logged in */}
                    <Route path="/" element={<Dashboard />} />
                    <Route exact path="/dashboard" element={<Dashboard />} />

                    {/* User Management Routes */}
                    <Route exact path="/appUser" element={<AppUserPage />} />
                    <Route exact path="/systemUser" element={<SystemUserPage />} />
                    <Route exact path="/addUser" element={<AddUser />} />
                    <Route exact path="/userDetail" element={<UserDetail />} />

                    {/* Role Management Routes */}
                    <Route exact path="/rolesList" element={<RolesList />} />
                    <Route exact path="/addRolesPermission" element={<AddRolesPermission />} />

                    {/* Blog Management Routes */}
                    <Route exact path="/blogManage" element={<BlogList />} />
                    <Route exact path="/createBlog" element={<CreateBlog />} />

                    {/* Report Management Routes */}
                    <Route exact path="/projectreports" element={<ProjectReport />} />
                    <Route exact path="/investmentreports" element={<InvestmentReport />} />
                    <Route exact path="/userreport" element={<UserReport />} />
                    <Route exact path="/investmentdetailpage" element={<InvestmentDetailPage />} />

                    {/* Project Management Routes */}
                    <Route exact path="/projects" element={<ProjectListPage />} />
                    <Route exact path="/projectDetail/:project_id" element={<ProjectDetail />} />
                    <Route exact path="/projectrepaymentconfiguration" element={<ProjectRepaymentConfiguration />} />
                    <Route exact path="/projectcloserconfiguration" element={<ProjectCloserConfiguration />} />

                    {/* Member Request Routes */}
                    <Route exact path="/memberRequest" element={<MemberRequestPage />} />
                    <Route exact path="/emailsubscribe" element={<EmailSubscriptions />} />

                    {/* Investor Info Routes */}
                    <Route exact path="/investorinfo" element={<Investor />} />
                    <Route exact path="/proposerinfo" element={<Proposer />} />
                    <Route exact path="/investorcommitteememberinfo" element={<InvestmentCommitteeMember />} />
                    <Route exact path="/liquidityproviderinfo" element={<LiquidityProvider />} />
                    <Route exact path="/serviceproviderinfo" element={<ServiceProvider />} />
                    <Route exact path="/broker" element={<Broker />} />
                    <Route exact path="/lawfirm" element={<LawFirm />} />
                    <Route exact path="/assetcustodyprovider" element={<AssetCustodyProvider />} />
                    <Route exact path="/decentralizefinancialadvisor" element={<DecentralizeFinancialAdvisor />} />
                    <Route exact path="/assetcustodyproviderdetails" element={<AssetCustodyProviderDetails />} />
                    <Route exact path="/others" element={<OthersRole />} />
                    <Route exact path="/techdeveloper" element={<TechDeveloper />} />
                    <Route exact path="/otherdetails" element={<OtherRoleDetailPage />} />

                    {/* Configuration Routes */}
                    <Route exact path="/RangeConfig" element={<RangeConfig />} />
                    <Route exact path="/HowItWorks" element={<HowItWorksConfiguration />} />
                    <Route exact path="/investmentterms" element={<InvestmentTerms />} />
                    <Route exact path="/repaymentrangeconfig" element={<RepaymentRangeConfigMaster />} />
                    <Route exact path="/dicConfigList" element={<DicConfigList />} />
                    <Route exact path="/faq" element={<Faq />} />
                    <Route exact path="/faqconfiguration" element={<FaqConfiguration />} />
                    <Route exact path="/dicMember" element={<DicMember />} />
                    <Route exact path="/dicConfig" element={<DicConfig />} />
                    <Route exact path="/reviewpage" element={<DicMemberReviewPage />} />
                    <Route exact path="/investmentcommitieedetails" element={<InvestmentCommitieDeailPage />} />

                    {/* Asset Checklist Master Routes */}
                    <Route exact path="/assetreviewlist" element={<AssetReviewList />} />
                    <Route exact path="/assetreviewconfiguration" element={<AssetReviewConfiguration />} />

                    {/* Terms and Privacy Policy Routes */}
                    <Route exact path="/termscondition" element={<TermsCondition />} />
                    <Route exact path="/termsconditionfoundation" element={<TermsConditionFoundation />} />
                    <Route exact path="/privacypolicy" element={<Privacypolicy />} />
                    <Route exact path="/privacypolicyfoundation" element={<PrivacypolicyFoundation />} />

                    {/* Service Provider Configuration and Skill Routes */}
                    <Route exact path="/serviceproviderconfig" element={<Serviceproviderconfig />} />
                    <Route exact path="/serviceproviderskill" element={<ServiceProviderSkill />} />
                    <Route exact path="/serviceproviderservices" element={<ServiceProviderSevices />} />
                    <Route exact path="/servicedetails" element={<ServiceDetails />} />
                    <Route exact path="/servicelist" element={<ServiceListPage />} />
                    <Route exact path="/servicecontactsupport" element={<ServiceContactSupport />} />
                    <Route exact path="/serviceproviderconfiguration" element={<ServiceProviderConfig />} />
                    <Route exact path="/serviceproviderlist" element={<ServiceProviderListPage />} />
                    <Route exact path="/serviceproviderdetails" element={<ServiceProviderDetails />} />
                    <Route exact path="/adminfeemodule" element={<AdminFeeModuleService />} />

                    {/* Miscellaneous Configuration Routes */}
                    <Route exact path="/profession" element={<Profession />} />
                    <Route exact path="/language" element={<Language />} />
                    <Route exact path="/subcategory" element={<SubCategory />} />
                    <Route exact path="/langingpageConfiguration" element={<LandingPageConfiguration />} />
                    <Route exact path="/transections" element={<TransectionList />} />
                    <Route exact path="/transectiondetails" element={<TransectionDetails />} />
                    <Route exact path="/auctionconfiguration" element={<AuctionConfiguration />} />
                    <Route exact path="/paymenttermconfiguration" element={<PaymentTerm />} />
                    <Route exact path="/fundinvestmentconfiguration" element={<FundInvestmentConfig />} />

                    {/* Logs and Miscellaneous Routes */}
                    <Route exact path="/activitylog" element={<ActivityLogs />} />

                    {/* Fallback for Undefined Routes */}
                    <Route path="*" element={<NotFound />} />

                  </Routes>

                </Suspense>
              </div>
            </div>
          </div>
        </>
      ) : (
        // Routes for when the user is not logged in
        <Routes>
          <Route path="/" element={<Login handleLogin={handleLogin} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </Router>
  );
}

export default AppRouter;
