import axios from 'axios';
import toast from 'react-hot-toast';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL,
});

let isRefreshing = false; // Flag to track token refresh process
let failedQueue = []; // Queue to store failed requests due to token expiration
let toastDisplayed = false; // Flag to track if toast message has been displayed

// Function to refresh token
const refreshToken = async () => {
  try {
    isRefreshing = true;
    const refresh_token = localStorage.getItem('adminrefreshtoken');
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/refreshToken`, {
      headers: {
        'x-refresh-token': refresh_token,
      },
    });

    localStorage.setItem('admintoken', response.data.data.token);
    localStorage.setItem('adminrefreshtoken', response.data.data.refresh_token);

    // Retry all failed requests
    failedQueue.forEach(prom => prom(response.data.data.token));
    failedQueue = [];

    // Reset toast display flag upon successful refresh
    toastDisplayed = false;
  } catch (err) {
    console.error('Token refresh failed:', err);
    // Handle refresh token failure (e.g., logout user)
    logoutUser();
  } finally {
    isRefreshing = false;
  }
};

// Function to logout user
const logoutUser = () => {
  const keysToRemove = [
    'adminuserdata',
    'admintoken',
    'adminlogin',
    'rememberMe',
    'username',
    'password',
    'adminrefreshtoken',
    'adminId',
    'name',
    'roles',
    'arrAble',
    'arrNotAble',
    'roleId',
    'addStatus',
    'userId'
  ];

  keysToRemove.forEach(key => localStorage.removeItem(key));

  if (!toastDisplayed) {
    toastDisplayed = true;
    toast('Session expired, please log in again.', {
      duration: 2000,
      position: 'top-right',
      icon: '⚠️',
      style: {
        background: '#333',
        color: '#fff',
      },
    });
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  }
};

// Axios interceptors
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('admintoken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      logoutUser();
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        refreshToken();
      }

      // Queue failed request due to token refresh process
      const retryOriginalRequest = new Promise((resolve, reject) => {
        failedQueue.push((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          axiosInstance(originalRequest).then(resolve).catch(reject);
        });
      });

      return retryOriginalRequest;
    }

    return Promise.reject(error);
  }
);

export const LoginAdmin = async (url, body) => {
  return await axiosInstance.post(url, body)
    .then(res => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const postApi = async (url, body) => {
  return await axiosInstance.post(url, body)
    .then(res => {
      /**
       * Setting Auth token to verify that after 15 minute session expires.
       */
      const token = res.headers['authtoken'];
      if (token) {
        localStorage.setItem('admintoken', token); // Store token in local storage
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const updateApi = async (url, body) => {
  return await axiosInstance.put(url, body)
    .then(res => {
      /**
       * Setting Auth token to verify that after 15 minute session expires.
       */
      const token = res.headers['authtoken'];
      if (token) {
        localStorage.setItem('admintoken', token); // Store token in local storage
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const GetUsers = async (url) => {
  try {
    const response = await axiosInstance.get(url);

    /**
     * Setting Auth token to verify that after 15 minute session expires.
     */
    const token = response.headers['authorization'];
    if (token) {
      localStorage.setItem('admintoken', token);
    } else {
      console.log("Token not found in headers");
    }

    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    return error;
  }
};

export const UpdateUser = async (url, id, body) => {
  return await axiosInstance.put(`${url}/${id}`, body)
    .then(res => {
      /**
       * Setting Auth token to verify that after 15 minute session expires.
       */
      const token = res.headers['authtoken'];
      if (token) {
        localStorage.setItem('admintoken', token); // Store token in local storage
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const UpdateApi = async (url, body) => {
  return await axiosInstance.put(url, body)
    .then(res => {
      /**
       * Setting Auth token to verify that after 15 minute session expires.
       */
      const token = res.headers['authtoken'];
      if (token) {
        localStorage.setItem('admintoken', token); // Store token in local storage
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const GetApiParam = async (url, body) => {
  return await axiosInstance.get(url, { params: body })
    .then(res => {
      /**
       * Setting Auth token to verify that after 15 minute session expires.
       */
      const token = res.headers['authtoken'];
      if (token) {
        localStorage.setItem('admintoken', token); // Store token in local storage
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const DeleteApi = async (url, body) => {
  return await axiosInstance.delete(url, { params: body })
    .then(res => {
      /**
       * Setting Auth token to verify that after 15 minute session expires.
       */
      const token = res.headers['authtoken'];
      if (token) {
        localStorage.setItem('admintoken', token); // Store token in local storage
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};
export const DeleteUser = async (url, body) => {
  return await axiosInstance.delete(url, { data: body })
    .then(res => {
      /**
       * Setting Auth token to verify that after 15 minute session expires.
       */
      const token = res.headers['authtoken'];
      if (token) {
        localStorage.setItem('admintoken', token); // Store token in local storage
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};
