import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const InvestmentDetailsFund = ({ _capitaltype, capitalInfo }) => {
  const [capitalType, setCapitalType] = useState(_capitaltype);

  useEffect(() => {
    if (_capitaltype) {
      setCapitalType(_capitaltype);
    }
  }, [_capitaltype]);

  return (
    <Container fluid>
      {/* Header based on _capitaltype */}
      {/* Render content based on class in capitalInfo */}
          <Row>
          {capitalInfo?.map((info) => (
            <Col md={3}>
              <div className='boxDetails'>
            <h3>{`Class ${info.class}`}</h3>
              <p><strong>Minimum Investment</strong>:{info.minimum_investment}</p>
              <p><strong>Hurdel Rate</strong>:{info.rate_of_return}%</p>
              </div>
            </Col>
      ))}
          </Row>
      

    </Container>
  );
};

export default InvestmentDetailsFund;
