import './App.scss';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './Router/Routes';
import { AuthProvider, useAuth } from './Auth/Login/AuthContext';
import WalletProvider from './web3/WalletProvider';
import { useEffect } from 'react';

function App() {
  return (
    <div className="">
      <WalletProvider>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </WalletProvider>
    </div>
  );
}

export default App;
