import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import ParcelCardGrid from './ParcelCardGrid';
import purify from "dompurify";

const DescriptionTab = ({ discription, parcelData }) => {

  const [data, setData] = useState(discription && JSON.parse(discription))
  const [activeTab, setActiveTab] = useState(null); // Set the default active tab to null

  /**
   * Setting Discription and data
   */
  useEffect(() => {
    if (discription) {
      const _discription = discription && JSON.parse(discription)
      setData(_discription)
      setActiveTab(_discription[0]?.title.toLowerCase())
    }
  }, [discription])

  /**
   * Handling Scroll according to the data for right side tab content
   */
  useEffect(() => {
    const verticalTabsSection = document.getElementById('vertical-tabs-section');

    const handleScroll = () => {
      const tabs = data.map((tab) => document.getElementById(tab.title.toLowerCase()));

      const scrollPosition = verticalTabsSection.scrollTop;

      tabs.forEach((tab, index) => {
        const tabHeight = tab.offsetHeight;
        const offsetTop = tab.offsetTop;

        if (scrollPosition >= offsetTop && scrollPosition < offsetTop + tabHeight) {
          setActiveTab(data[index].title.toLowerCase());
        }
      });
    };

    verticalTabsSection.addEventListener('scroll', handleScroll);

    return () => {
      verticalTabsSection.removeEventListener('scroll', handleScroll);
    };
  }, [data]);

  /**
   * Function for the Tab selection
   * @param {*} tabKey 
   */
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <Container fluid className='tabSectionMain'>
      <Row>
        {/* Left Sidebar with Vertical Tabs */}
        <Col lg={3} md={6} className="sidebar d-flex flex-column tab-inner-col">
          <div id="vertical-tabs-section" className='leftMenu'>
            <Tabs id="vertical-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="flex-column">
              {data?.map((tab) => (
                <Tab key={tab.title.toLowerCase()} eventKey={tab.title.toLowerCase()} title={tab.title}>
                </Tab>
              ))}
              {/* Add Parcel tab if parcelData has length */}
              {parcelData?.length > 0 && (
                <Tab eventKey="parcel" title="Parcel">
                </Tab>
              )}
            </Tabs>
          </div>
        </Col>

        {/* Right Content Area */}
        <Col lg={9} md={6} className="ml-sm-auto col-lg-9 px-4 tab-inner-col  ">
          {/* Render content based on the active tab */}
          <div className='contentBoxRight'>
            {data?.map((tab) => (
              <div
                key={tab.title.toLowerCase()}
                id={tab.title.toLowerCase()}
                style={{
                  maxHeight: activeTab === tab.title.toLowerCase() ? '400px' : '0px',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  transition: 'max-height  ease',
                }}
              >
                {/* Tab content goes here */}
                <Form.Group controlId={tab.title.toLowerCase()}>
                  <div style={{ paddingBottom: '20px' }} dangerouslySetInnerHTML={{ __html: purify.sanitize(tab?.description) }} />

                </Form.Group>
              </div>
            ))}
          </div>
          {/* Rendering ParcelCardGrid component if Parcel tab is active */}
          {activeTab === 'parcel' && (
            <div style={{
              maxHeight: '400px',
              overflowY: 'auto',
              overflowX: 'hidden',
              transition: 'max-height  ease',
            }}>
              <ParcelCardGrid cardsData={parcelData} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DescriptionTab;
