import { readContract, prepareWriteContract, writeContract, waitForTransaction } from "@wagmi/core";
import { CONTRACT_ABI, CONTRACTS } from "./Contracts";
import { CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID ,INVESTMENT_TYPE_ID } from "../components/Common/Constant/Constant";


/**
 * 
 * @param {*} data 
 * @returns 
 */
async function ManageSuperDic(address, proposalId, indexing, memberAddress,contractAddressAbi) {
    try {

        // let contract_address = CONTRACTS.DIC_PROPOSAL
        // if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
        //     contract_address = CONTRACTS.DIC_PROPOSAL_SALE
        // } else if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
        //     contract_address = CONTRACTS.DIC_PROPOSAL_PARCEL
        // } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
        //     contract_address = CONTRACTS.DIC_PROPOSAL_LOAN
        // } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
        //     contract_address = CONTRACTS.DIC_PROPOSAL_SPACE_X_FUND
        // } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
        //     contract_address = CONTRACTS.DIC_PROPOSAL_OPEN_FUND
        // }

        console.log("contract calles")
        const { request: ManageSuperDicMember } = await prepareWriteContract({
            address:contractAddressAbi[0]?.address,
            abi: contractAddressAbi[0]?.abi,
            account: address,
            chainId: 6805,
            functionName: "manageSuperDIC",
            args: [
                proposalId,
                indexing,
                memberAddress
            ]

        });
        console.log("ManageSuperDicMember...",ManageSuperDicMember)
        const { hash } = await writeContract(ManageSuperDicMember);
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            console.log("data..", data)
            if (data.status == 'success') {
                return data
            }
        }

    } catch (error) {
        console.error('Error in calling dic approve:', error);
        throw error; // propagate the error
    }
}

export { ManageSuperDic };
