import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Session() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('admintoken');

  useEffect(() => {
    if (location.pathname === "/" && token) {
      navigate('/dashboard'); // Redirect to dashboard if logged in
    } else if (token === null) {
      navigate("/"); // Redirect /adminpanel to /
    }
  }, [location.pathname, navigate, token]);

  return null; // No UI needed
}
