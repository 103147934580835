import React, { useState, useEffect } from 'react';
import { Col, Card, Button, Form, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { GetApiParam, GetUsers } from '../../Api/api';
import { AssigndSuperDic } from '../../web3/AssigndSuperDic';
import { useAccount } from 'wagmi';
import { ManageSuperDic } from '../../web3/ManageSuperDic';
import toast from 'react-hot-toast';
import { GetContractAddress } from '../../web3/GetContractAddress';
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from '../Common/Constant/Constant';

const AssignSuperDic = ({ ProjectData }) => {
    const [allocatedDic, setAllocatedDic] = useState('');
    const [superDic, setSuperDic] = useState('');
    const [superDicData, setSuperDicData] = useState([]);
    const [allocateDicData, setAllocateDicData] = useState([]);
    const [contractAddressAbi ,setContractAddressAbi] = useState("")
    const [loading, setLoading] = useState(false)
    const { project_id } = useParams();
    const { address, isConnecting, isDisconnected } = useAccount()
    // State for validation error messages
    const [errors, setErrors] = useState({
        allocatedDic: '',
        superDic: ''
    });


    // checking wallet address availability
    useEffect(() => {
        if (!address) {
            toast("Please connect wallet", {
                duration: 3000,
                position: 'top-right',
                type: 'error',
            })
        }
    }, [address])


    /**
     * Function to Get superDic List
     * @param {*} address 
     */
    const getSuperDic = async (address) => {
        try {
            const payload = {
                asset_id: ProjectData.id,
                wallet_address: address
            }
            const res = await GetApiParam(`/admin/getEligibleSupDic`, payload);
            if (res.data) {
                setSuperDicData(res?.data?.data ? res?.data?.data : [])
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Setting wallet address for the payload for superDic
     */
    useEffect(() => {
        if (allocatedDic) {
            const WalletAddress = allocateDicData[allocatedDic]
            getSuperDic(WalletAddress)
        }

    }, [allocatedDic, allocateDicData])

    /**
     * Calling Smartcontract for getting the current assigned Dic
     */
    useEffect(() => {
        if (project_id && ProjectData) {
            async function getAllocatedDic() {

                let contract_name = ""
                if (ProjectData?.assets_category?.id === CATEGORY_TYPE_ID.ART && ProjectData?.investment_type?.id === INVESTMENT_TYPE_ID.SALE) {
                    contract_name = "DICSaleProposal"
                } else if (ProjectData?.assets_category?.id === CATEGORY_TYPE_ID.ART && ProjectData?.investment_type?.id === INVESTMENT_TYPE_ID.PARCEL) {
                    contract_name = "DICParcelProposal"
                } else if (ProjectData?.investment_type?.id === INVESTMENT_TYPE_ID.LOAN) {
                    contract_name = "DICLoanProposal"
                } else if (ProjectData?.assets_category?.id === CATEGORY_TYPE_ID.FUNDS && ProjectData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                    contract_name = "DICCloseFundProposal"
                } else if (ProjectData?.assets_category?.id === CATEGORY_TYPE_ID.FUNDS) {
                    contract_name = "DICFundProposal"
                }

                let getContractABI = ""
                try {
                    /**
                     * Calling method to get the abi and the contract adress for the respected version
                     */
                    const payload = {
                        category_id: ProjectData?.assets_category?.id,
                        investment_type_id: ProjectData?.investment_type?.id,
                        asset_sub_category: ProjectData?.asset_sub_category
                    }
                    getContractABI = await GetContractAddress(payload, ProjectData?.contract_version, contract_name)
                    setContractAddressAbi(getContractABI)

                } catch (error) {
                    console.log("error")
                }

                const AssigneDicData = await AssigndSuperDic(address, ProjectData?.blockchain_id, getContractABI)
                setAllocateDicData(AssigneDicData)
            }
            if (ProjectData?.blockchain_id) {
                getAllocatedDic()
            }
        }
    }, [project_id, ProjectData])

    // Handle dropdown changes
    const handleAssignedDic = (e) => {
        setAllocatedDic(e.target.value);
        setErrors({ ...errors, allocatedDic: '' }); // Clear validation error
    };

    /**
     * Handle dropdown changes for SuperDic
     * @param {*} e 
     */
    const handleSuperDic = (e) => {
        setSuperDic(e.target.value);
        setErrors({ ...errors, superDic: '' }); // Clear validation error
    };

    // Handle form submission
    const handleSubmit = async () => {
        let validationErrors = {};
        if (!allocatedDic) {
            validationErrors.allocatedDic = 'Please select an allocated DIC for which you need to assign a super DIC.';
        }

        if (!superDic) {
            validationErrors.superDic = 'Please select a super DIC.';
        }

        if (Object.keys(validationErrors).length === 0) {
            // Submit the form (handle as needed)
            if (address) {
                setLoading(true)
                try {
                    const ManageSuperDicRes = await ManageSuperDic(address, ProjectData?.blockchain_id, allocatedDic, superDic ,contractAddressAbi)
                    if (ManageSuperDicRes.status == "success") {
                        setLoading(false)
                    }
                } catch (error) {
                    console.log("error", error)
                    // Convert error to a string and check for the revert reason
                    const errorString = String(error);

                    // Check if the error contains the contract revert reason
                    if (errorString.includes('reverted with the following reason:')) {
                        // Extract the reason dynamically
                        const reason = errorString.split('reverted with the following reason:')[1].trim().split('\n')[0];

                        // Display the extracted reason (e.g., "Subcategory name already exists")
                        toast.error(reason);
                        setLoading(false)
                    } else {
                        // Generic fallback if the error doesn't contain the expected format
                        if (errorString.toLowerCase().includes("rejected")) {
                            toast.error("Transaction rejected by the user.");
                            setLoading(false)
                        }
                    }
                }
            } else {
                toast("Please connect your wallet", {
                    duration: 3000,
                    position: 'top-right',
                    type: 'error'
                });
            }
        } else {
            setErrors(validationErrors);
            setLoading(false)
        }
    };

    return (
        <div>
            <Col md={8}>
                <Card className='class-style'>
                    <Card.Body>
                        <h2 className="section-title">Assigned Dic List</h2>
                        <Form.Group controlId="allocatedDic">
                            <Form.Label>Assigned Dic</Form.Label>
                            <Form.Control as="select" value={allocatedDic} onChange={handleAssignedDic}>
                                <option value="">Select...</option>
                                {allocateDicData?.map((option, idx) => (
                                    <option key={idx} value={idx}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                            {errors.allocatedDic && <div className="text-danger mt-2">{errors.allocatedDic}</div>}
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>

            <Col md={8}>
                <Card className="address-card">
                    <Card.Body>
                        <h2 className="section-title">Super Dic List</h2>
                        <Form.Group controlId="superDic">
                            <Form.Label>Super Dic</Form.Label>
                            <Form.Control as="select" value={superDic} onChange={handleSuperDic}>
                                <option value="">Select...</option>
                                {superDicData?.length && superDicData?.map((option, idx) => (
                                    <option key={idx} value={option.wallet_address}>
                                        {option.first_name} {option.last_name}
                                    </option>
                                ))}
                            </Form.Control>
                            {errors.superDic && <div className="text-danger mt-2">{errors.superDic}</div>}
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>

            <Col md={12} className="text-center mt-3 mb-2">
                <Button variant="primary" onClick={handleSubmit}>Submit</Button>
            </Col>
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 9999,
                    }}
                >
                    <Spinner animation="border" variant="light" />
                </div>
            )}
        </div>
    );
};

export default AssignSuperDic;
