/**
 * This file contains the contract addresses and ABI's
 */


// variable to switch between mainnet and Staging
var isStaging = true;



/**
 * @constant {object} CONTRACTS - The contract addresses
 */
export const CONTRACTS = {
    // USER: isStaging ? "0x830a6a094B37070dE6FfaEFB338972a2359Cd738" : "0xCf6A32dB8b3313b3d439CE6909511c2c3415fa32",
    // DIC_MANAGE: isStaging ? "0xe7Bc9BBafB2AE7B0fAaF09513d50d8bD355Cd1c9" : "0xf54B2BAEF894cfF5511A5722Acaac0409F2F2d89",
    // ADMIN: isStaging ? "0x56741Fd7ddBB6eaD29D43676327AE4138B134114" : "0x0f33D824d74180598311b3025095727BeA61f219",
    // LOAN_REPAYMENT_ADDRESS: isStaging ? "0xa7c8e50f9D6bE0FD9E368028E83c4a4C6D9C4123" : "0x36eF8d1C6b536C08095ABaAE8a4D3EFCD3653825",
    // SPV_REPAYMENT_ADDRESS: isStaging ? "0x1E712fE86f32889B34D6A107636535fD37B487F8" : "",
    // SPACE_X_FUND_INVESTMENT: isStaging ? "0xA6fC69E8CcD68CDc9C84F4a6924F92F5FaA18A3F" : "",
    CATEGORY_SKILL_REGISTRY: isStaging ? "0xFA5eD33efe0D8d08752cAf862FC8817C64Ab1feC" : "",
    ADMIN_CONFIGURATION_SERVICEPROVIDER: isStaging ? "0xE803Cea326b54708f45b453C38876c54B106a7Ae" : "",
    ESCROW_WALLET: isStaging ? "0x856526e35d1DbA882A440c7B98440e645a1fbCa7" : "",

    /**
     * DIC PROPOSAL
     */
    DIC_PROPOSAL_SALE: isStaging ? '0xE7c828959c5FAD7b9b4D7a1d25aef66a16313d9A' : "",
    DIC_PROPOSAL_PARCEL: isStaging ? '0xBf3b0Ad8a1cBc9123bEd3BC9B334CcE703b792FF' : "",
    DIC_PROPOSAL_LOAN: isStaging ? '0x76e0F8c5D88d2f280394a536Ed4F9aE387dE0C0F' : "",
    DIC_PROPOSAL_OPEN_FUND: isStaging ? '0x0c3Dd78eB3e2144424db1c5981Fb1d6Ab454dE10' : "",
    DIC_PROPOSAL_SPACE_X_FUND: isStaging ? '0x4231e84f0CA4b1B51bfb62194605b745065c2106' : "",
}


/**
 * @constant {object} CONTRACT_ABI - The contract ABI's
 */
export const CONTRACT_ABI = {
    [CONTRACTS.USER]: isStaging ? require("./abi/UserStag.json") : require("./abi/User.json"),
    [CONTRACTS.DIC_MANAGE]: isStaging ? require("./abi/DicManageStage.json") : require("./abi/DicManage.json"),
    [CONTRACTS.ADMIN]: isStaging ? require("./abi/AdminStage.json") : require("./abi/Admin.json"),
    [CONTRACTS.LOAN_REPAYMENT_ADDRESS]: isStaging ? require("./abi/LoanRepaymentStage.json") : require("./abi/LoanRepayment.json"),
    [CONTRACTS.SPV_REPAYMENT_ADDRESS]: isStaging ? require("./abi/SpvRepaymentStag.json") : require("./abi/SpvRepayment.json"),
    [CONTRACTS.SPACE_X_FUND_INVESTMENT]: isStaging ? require("./abi/SpacexFundInvestmentStage.json") : require("./abi/SpacexFundInvestment.json"),
    [CONTRACTS.CATEGORY_SKILL_REGISTRY]: isStaging ? require("./abi/CategorySkillStage.json") : require("./abi/CategorySkill.json"),
    [CONTRACTS.DIC_PROPOSAL_OPEN_FUND]: isStaging ? require("./abi/FundDicProposalStage.json") : require("./abi/FundDicProposal.json"),
    [CONTRACTS.DIC_PROPOSAL_SPACE_X_FUND]: isStaging ? require("./abi/FundSpaceXDicProposalStage.json") : require("./abi/FundSpaceXDicProposal.json"),
    [CONTRACTS.DIC_PROPOSAL_SALE]: isStaging ? require("./abi/DicProposalSaleSatge.json") : require("./abi/DicProposalSale.json"),
    [CONTRACTS.DIC_PROPOSAL_PARCEL]: isStaging ? require("./abi/DicProposalParcelStage.json") : require("./abi/DicProposalParcel.json"),
    [CONTRACTS.DIC_PROPOSAL_LOAN]: isStaging ? require("./abi/DicProposalLoanStage.json") : require("./abi/DicProposalLoan.json"),
    [CONTRACTS.ADMIN_CONFIGURATION_SERVICEPROVIDER]: isStaging ? require("./abi/AdminServiceProviderConfigurationStage.json") : require("./abi/AdminServiceProviderConfiguration.json"),
    [CONTRACTS.ESCROW_WALLET]: isStaging ? require("./abi/EscrowWalletStage.json") : require("./abi/EscrowWallet.json"),
}
