// GoldDetails.jsx

import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const GoldDetails = ({ GoldData }) => {

  return (
    <Container fluid style={{ marginTop: '20px' }}>
      <Row className="mb-4">
        <Col md={6}>
          {/* Gold Details Section */}
          <Card className="gold-card">
            <Card.Body>
              <h2 className="section-title">{GoldData && GoldData?.gold_title}</h2>
              <p className="detail-text">
                <strong>Condition:</strong> {GoldData && GoldData?.gold_condition}
              </p>
              <p className="detail-text">
                <strong>Estimated Value:</strong> {GoldData && GoldData?.gold_estimated}
              </p>
              <p className="detail-text">
                <strong>Quality:</strong> {GoldData && GoldData?.gold_quality}
              </p>
              <p className="detail-text">
                <strong>Weight:</strong> {GoldData && GoldData?.gold_weight}
              </p>
              <p className="detail-text">
                <strong>Owned by:</strong> {GoldData && GoldData?.owner_information?.owner_type}
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          {/* Gold Address Section */}
          <Card className="address-card">
            <Card.Body>
              <h2 className="section-title">Address and Location</h2>
              <p className="detail-text">
                <strong>Address:</strong> {GoldData && GoldData?.gold_address}
              </p>
              <p className="detail-text">
                <strong>Floor:</strong> {GoldData && GoldData?.gold_floor}
              </p>
              <p className="detail-text">
                <strong>City:</strong> {GoldData && GoldData?.gold_city}
              </p>
              <p className="detail-text">
                <strong>State:</strong> {GoldData && GoldData?.gold_state}
              </p>
              <p className="detail-text">
                <strong>Country:</strong> {GoldData && GoldData?.gold_country}
              </p>
              <p className="detail-text">
                <strong>Zipcode:</strong> {GoldData && GoldData?.gold_zipcode}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};


export default GoldDetails;
