import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === 'true');
  const [isLoggedIn, setLoggedIn] = useState(false);

  const login = () => {
    setLoggedIn(true);
  };

  const logout = () => {
    setLoggedIn(false);
    if (!rememberMe) {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }
    localStorage.removeItem('rememberMe');
    localStorage.removeItem("blogStatus");
    localStorage.removeItem("blogId");
    localStorage.removeItem("adminId");
    localStorage.removeItem("addStatus");
    localStorage.removeItem("roles");
    localStorage.removeItem('name');
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminrefreshtoken');
    localStorage.removeItem('AdminData');
  };

  const toggleRememberMe = (isChecked) => {
    setRememberMe(isChecked);
    localStorage.setItem('rememberMe', isChecked);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, rememberMe, toggleRememberMe }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
