import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';

const WatchDetailsPage = ({ WatchData }) => {
  const [data, setData] = useState(WatchData);

  useEffect(() => {
    if (WatchData) {
      const data_watch = WatchData;
      setData(data_watch);
    }
  }, [WatchData]);

  return (
    <Container fluid className='container-style'>
      <Row className="">
        <Col md={12}>
          {/* Watch Details Section */}
          <Card className='class-style'>
            <Card.Body>
              <h2 className='title-style'>{data && data?.asset_title}</h2>
              <p className='p-style'>
                <strong>Brand:</strong> {data && data?.watch_brand}
              </p>
              <p className='p-style'>
                <strong>Condition:</strong> {data && data?.watch_condition}
              </p>
              <p className='p-style'>
                <strong>Estimated Value:</strong> {data && data?.watch_estimated}
              </p>
              <p className='p-style'>
                <strong>Manufacturing Year:</strong> {data && data?.watch_year}
              </p>
              <p className='p-style'>
                <strong>Model:</strong> {data && data?.watch_model}
              </p>
              <p className="detail-text">
                <strong>Owned by:</strong> {data && data?.owner_information?.owner_type}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WatchDetailsPage;
