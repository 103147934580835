import React from 'react';
import { Card, Button, Image } from 'react-bootstrap';

const ParcelCard = ({ card, viewOnly }) => {
  return (
    <Card className={`rc-card-container ${card?.is_sold && 'is_sold'} custom-background`} style={{ marginBottom: '16px' }}>
      <Card.Img variant='top' src={`${process.env.REACT_APP_IMAGE_URL}${card?.imagess?.path}`} alt='Img' style={{ width: '100%', height: '137px', borderRadius: '16px' }} />
      <Card.Body className='investor-card'>
        <Card.Title className='font-16'>{card?.title}</Card.Title>
        {viewOnly && card.is_sold ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className='font-12 text-blueGray-400'>${card?.price}</span>
            <div>
              <Button className='sold-btn-red' style={{ margin: '3px 0px' }} disabled>Sold</Button>
            </div>
          </div>
        ) : (
          <Card.Text className='font-12 text-blueGray-400' style={{ marginBottom: '8px' }}>
            ${card?.price}
          </Card.Text>
        )}
        {!viewOnly && (
          <>
            {card.is_sold ? (
              <div style={{ marginBottom: '13px' }}>
                <Button className='sold-btn-red' disabled>Sold</Button>
              </div>
            ) : null}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ParcelCard;
