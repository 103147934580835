import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';

const RealEstateDetails = ({ realEstateData, from }) => {
  const [data, setData] = useState(realEstateData);

  useEffect(() => {
    if (realEstateData) {
      const data_estate = realEstateData;
      setData(data_estate);
    }
  }, [realEstateData]);


  return (
    <Container fluid className='container-style'>
      <Row className="">
        <Col md={6}>
          <Card className='class-style'>
            <Card.Body>
              <h2 className='title-style'>{data && data?.asset_title}</h2>
              <p className='p-style'>
                <strong>Project Duration:</strong> {data && data?.fractionalize_duration_of_project}
              </p>
              {from !== "fund" &&
                <p className='p-style'>
                  <strong>Project Size:</strong> {data && data?.fractionalize_project_size}
                </p>
              }
              <p className='p-style'>
                <strong>Total Price:</strong> {data && data?.fractionalize_total_price}
              </p>
              <p className="p-style">
                <strong>Owned by:</strong> {data && data?.owner_information?.owner_type}
              </p>
            </Card.Body>
          </Card>
        </Col>
        {from !== "fund" &&
          <Col md={6}>
            {/* Real Estate Address Section */}
            <Card className="address-card">
              <Card.Body>
                <h2 className="section-title">Address and Location</h2>

                <p className="detail-text">
                  <strong>Asset Address:</strong> {realEstateData && realEstateData?.asset_address}
                </p>
                {/* <p className="detail-text">
                  <strong>Asset Location:</strong> {realEstateData && realEstateData?.asset_location}
                </p> */}

              </Card.Body>
            </Card>
          </Col>
        }
      </Row>
    </Container>
  );
};

export default RealEstateDetails;
