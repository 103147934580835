import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ParcelCard from './PacelCard';

const ParcelCardGrid = ({ cardsData }) => {
  return (
    <Row xs={1} md={2} lg={3} className="g-4">
      {cardsData && cardsData?.length > 0 ? (
        cardsData.map((card, index) => (
          <Col key={index}>
            <ParcelCard card={card} viewOnly={true} />
          </Col>
        ))
      ) : (
        <Col>
          <p className="text-center">No Parcels available</p>
        </Col>
      )}
    </Row>
  );
};

export default ParcelCardGrid;
