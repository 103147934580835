import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { IoIosEye } from 'react-icons/io';
import FileViewer from 'react-file-viewer';

const DefaultTemplate = ({ templateData }) => {
  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [viewedTemplates, setViewedTemplates] = useState([]);
  const [loading, setLoading] = useState(false);


  const viewDoc = async (documentdata, mode, index) => {
    setLoading(true);

    try {
      // Check if the PDF has been viewed before
      const isViewed = viewedTemplates?.filter((item) => item?.templateId === documentdata?.templateId);

      if (isViewed.length) {
        setFile(isViewed[0]?.templateUrl);
        setType('pdf');

        // PDF has been viewed before, no need to fetch again
        setOpenModal(true);
        setView(true);
        setLoading(false);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/docusign/viewTemplate?templateId=${documentdata?.templateId}`);

      const templateBuffer = await response.arrayBuffer();
      const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
      setType('pdf');

      // Mark the template as viewed and store in local state
      setViewedTemplates(prevViewedTemplates => [
        ...prevViewedTemplates,
        { templateId: documentdata.templateId, templateUrl }
      ]);

      setOpenModal(true);
      setView(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setView(false);
  };

  return (
    <>
      <div style={{ margin: '20px' }}>
        {templateData?.map((data, ind) => (
          <div style={{ display: 'flex',  alignItems: 'center', width: '100%', marginTop: "8px" ,flexWrap:'wrap'}} key={ind}>
            <div style={{marginBottom: "8px" }}>
              <span style={{ fontWeight: '500' }}>{data?.name}</span>
            </div>
            <div className='ml-auto' style={{ display: 'flex', gap: '20px' }}>
              <Button
                variant="primary"
                className="square-icon-btn"
                onClick={() => viewDoc(data)}
                style={{ display: "flex", marginLeft: "10px" }}
              >
                <IoIosEye />
              </Button>
            </div>
          </div>
        ))}
      </div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }}
        >
          <Spinner animation="border" variant="light" />
        </div>
      )}
      {(view && !loading) && (
        <Modal
          show={openModal}
          onHide={handleClose}
          size="lg"
          centered
          dialogClassName="custom-modal"
          className="projectDocumentsModal"
        >
          <Modal.Body className='kyc-docs'>
            <FileViewer fileType={type} filePath={file} />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default DefaultTemplate;
