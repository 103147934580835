import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from '../Common/Constant/Constant';

const DealTerms = ({ DealTermData }) => {
    const [data, setData] = useState(DealTermData);

    useEffect(() => {
        if (DealTermData) {
            const data_estate = DealTermData;
            setData(data_estate);
        }
    }, [DealTermData]);

    return (
        <Container fluid className='container-style'>
            <Row className="">
                <Col md={12}>
                    <Card className='class-style'>
                        <Card.Body>
                            {(
                                data?.assets_category?.id === CATEGORY_TYPE_ID.GOLD ||
                                data?.assets_category?.id === CATEGORY_TYPE_ID.ASSET ||
                                data?.assets_category?.id === CATEGORY_TYPE_ID.WATCH
                            ) ? (
                                <>
                                    {data?.suggestReviewData?.length ?
                                        <p className='p-style'>
                                            <strong>Loan Amount:</strong> {`$${parseFloat(data?.suggestReviewData[0]?.loan_amount_from)} - $${parseFloat(data?.suggestReviewData[0]?.loan_amount_to)}`}
                                        </p> :
                                        <p className='p-style'>
                                            <strong>Loan Amount:</strong> {data && `$${data?.loan_amount}`}
                                        </p>
                                    }

                                    {data?.suggestReviewData?.length ?
                                        <p className='p-style'>
                                            <strong>Loan Duration:</strong> {`${parseFloat(data?.suggestReviewData[0]?.loan_duration_from)}-${parseFloat(data?.suggestReviewData[0]?.loan_duration_to)} Month`}
                                        </p>
                                        :
                                        <p className='p-style'>
                                            <strong>Loan Duration:</strong> {data && `1 - ${data?.loan_duration_month} Month`}
                                        </p>
                                    }


                                    {data?.suggestReviewData?.length ?
                                        <p className='p-style'>
                                            <strong>Interest Rate:</strong> {`${parseFloat(data?.suggestReviewData[0]?.loan_roi_from)} - ${parseFloat(data?.suggestReviewData[0]?.loan_roi_to)}%`}
                                        </p>
                                        :
                                        <p className='p-style'>
                                            <strong>Interest Rate:</strong> {data && `1- ${data?.loan_roi}%`}
                                        </p>
                                    }

                                    <p className='p-style'>
                                        <strong>Interest Only Period:</strong> {data && `${data?.loan_iop}Month`}
                                    </p>
                                    <p className='p-style'>
                                        <strong>Minimum Investment:</strong> {data && `$${parseFloat(data?.loan_minimum_investment)}`}
                                    </p>
                                    <p className='p-style'>
                                        <strong>Payment Date:</strong> {data && `${data?.loan_repay_day}`}
                                    </p>
                                </>
                            ) : (
                                data?.assets_category?.id === CATEGORY_TYPE_ID.FUNDS && data?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS ? (
                                    <>
                                        <p className='p-style'>
                                            <strong>Total Raise:</strong>  {data && `$${data?.fractionalize_total_price}`}
                                        </p>
                                        <p className='p-style'>
                                            <strong>Initial Project Term:</strong> {data && `${data?.fractionalize_duration_of_project} year`}
                                        </p>
                                        {data?.capital_info && data?.capital_info?.length ?
                                            data?.capital_info?.map((val, item) => {
                                                return <>
                                                    <p className='p-style'>
                                                        <strong>class:</strong> {val.class == "1" ? "A" : val.class == "2" ? "B" : null}
                                                    </p>
                                                    <p className='p-style'>
                                                        <strong>Hurdle Rate:</strong> {`${val.rate_of_return ? val.rate_of_return : 0} %`}
                                                    </p>
                                                    <p className='p-style'>
                                                        <strong>Minimum investment:</strong> {`$ ${val.minimum_investment ? val.minimum_investment : 0}`}
                                                    </p>
                                                </>
                                            })
                                            : null
                                        }
                                    </>
                                ) : (
                                    data?.assets_category?.id === CATEGORY_TYPE_ID.ART && data?.investment_type_id == 1 ?
                                        <>
                                            <p className='p-style'>
                                                <strong>Sale Price:</strong>  {data && `$${parseFloat(data?.sale_price)}`}
                                            </p>
                                            <p className='p-style'>
                                                <strong>Deposite Amount:</strong> {data && `$${parseFloat(data?.sale_deposit_amount)} `}
                                            </p>
                                        </>
                                        : null
                                )
                            )}
                        </Card.Body>

                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default DealTerms;
