import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';

const InvestDetailsParcel = ({ ParcelDetails}) => {

  return (
    <Container fluid className='container-style'>
      <Row className="">
        <Col md={12}>
          {/* Watch Details Section */}
          <Card className='class-style'>
            <Card.Body>
              <h2 className='title-style'>{ParcelDetails && ParcelDetails?.asset_title}</h2>
              <p className='p-style'>
                <strong>Parcel Total Price:</strong> {ParcelDetails && ParcelDetails?.parcel_total_price}
              </p>
              <p className='p-style'>
                <strong>No of Parcel:</strong> {ParcelDetails && ParcelDetails?.number_of_parcel}
              </p>
              <p className='p-style'>
                <strong>Minimum Investment Per Parcel:</strong> {ParcelDetails && ParcelDetails?.minimun_investment_per_parcel}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InvestDetailsParcel;
