
/**
 * @type {import('@wagmi/core').Chain}
 */
export const race = {
  id: 6805, // mainnet
  // id: 6807, // devnet
  // id: 6806,
  name: 'RACE Mainnet', // mainnet
  // name: 'RACE Devnet', // devnet
  // name: 'RACE Testnet',
  network: 'RACE',
  nativeCurrency: {
    decimals: 18,
    name: 'RACE',
    symbol: 'RACE',
  },
  rpcUrls: {
    public: { http: ['https://racemainnet.io/'] }, // mainnet
    // public: { http: ['http://43.204.127.52/'] }, 
    // public: { http: ['https://racetestnet.io/'] },// devnet
    // public: { http: ['https://fragrant-convincing-glade.race-sepolia.quiknode.pro/ed3ad2a6d1c8f39fcf700aee25fe2200f8318e83/'] },
    default: { http: ['https://racemainnet.io/'] }, // mainnet
    // default:{ http: ['https://racetestnet.io/'] },// devnet
    // default: { http: ['http://43.204.127.52/'] }, 
    // default: { http: ['https://fragrant-convincing-glade.race-sepolia.quiknode.pro/ed3ad2a6d1c8f39fcf700aee25fe2200f8318e83/'] },
  },
  blockExplorers: {
    etherscan: { name: 'Race', url: 'https://racescan.io/' }, // mainnet
    // etherscan: { name: 'Race', url: 'http://13.202.86.5/' }, // devnet
    // etherscan: { name: 'Race', url: 'https://testnet.racescan.io/' },
    default: { name: 'Race', url: 'https://racescan.io/' }, // mainnet
    // default: { name: 'Race', url: 'http://13.202.86.5/' }, // devnet
    // default: { name: 'Race', url: 'https://testnet.racescan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 11_907_934,
    },
  },
} 