import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';

const InvestmentDetails = ({ capitalType, capitalInfo }) => {
    const [data, setData] = useState(capitalType && JSON.parse(capitalType));
    const [activeTab, setActiveTab] = useState(null);
  
    useEffect(() => {
      if (capitalType) {
        const _capitaltype = capitalType && JSON.parse(capitalType);
        setData(_capitaltype);
        setActiveTab(_capitaltype[0]); // Set the default active tab to the first one
      }
    }, [capitalType]);
  

    /**
     * Onclick function for the Tab
     * @param {*} tabKey 
     */
    const handleTabSelect = (tabKey) => {
      setActiveTab(tabKey);
    };
  
    /**
     * Rendering Details for the Investment Type
     * @param {*} details 
     * @returns 
     */
    const renderDetails = (details) => (
      <Row>
        {details.map((info) => (
          <Col md={6} key={info.id}>
            <div>
              {/* <h3>{info.capital_type.capital_name}</h3> */}
              <p><strong>Total Raise</strong>: {info.total_raise}</p>
              {activeTab === 'Equity' && <p><strong>Equity</strong>: {info.equity || 'N/A'}</p>}
              <p><strong>Minimum Investment</strong>: {info.minimum_investment}</p>
              <p><strong>Rate of Return</strong>: {info.rate_of_return}%</p>
            </div>
          </Col>
        ))}
      </Row>
    );
  
    return (
      <Container fluid>
        <Row>
          {/* Left Sidebar with Vertical Tabs */}
          <Col className="sidebar d-flex flex-column">
            <div id="vertical-tabs-section" className='verticalMenu' style={{ overflowY: 'auto', maxHeight: '400px' }}>
              <Tabs id="vertical-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="flex-column">
                {data?.map((tab) => (
                  <Tab key={tab} eventKey={tab} title={tab}>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </Col>
  
          {/* Right Content Area */}
          <Col md={9} className="ml-sm-auto col-lg-9 px-4">
            {/* Render content based on the active tab */}
            {capitalInfo?.filter((info) => activeTab === info.capital_type.capital_name).map((info, index, array) => (
              (index % 2 === 0) && renderDetails(array.slice(index, index + 2))
            ))}
          </Col>
        </Row>
      </Container>
    );
  };

export default InvestmentDetails;
