import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';

const InvestDetailsWatch = ({ WatchInvestment}) => {
  const [data, setData] = useState(WatchInvestment);

  useEffect(() => {
    if (WatchInvestment) {
      const data_watch = WatchInvestment;
      setData(data_watch);
    }
  }, [WatchInvestment]);

  return (
    <Container fluid className='container-style'>
      <Row className="">
        <Col md={12}>
          {/* Watch Details Section */}
          <Card className='class-style'>
            <Card.Body>
              <h2 className='title-style'>{data && data?.asset_title}</h2>
              <p className='p-style'>
                <strong>Loan Amount:</strong> {data && data?.loan_amount}
              </p>
              <p className='p-style'>
                <strong>Loan Duration(In Months):</strong> {data && data?.loan_duration_month}
              </p>
              <p className='p-style'>
                <strong>Rate Of Interest:</strong> {data && data?.loan_roi} %
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default InvestDetailsWatch;
